<template>
  <ion-page>
    <!-- Content -->
    <ion-content>
      <!-- Header -->
      <ModalHeader page="post-options" />
      <!-- Content Container -->
      <div class="app-max-width">
        <ion-list>
          <ion-radio-group :value="sortBy">

            <ion-list-header>
              <ion-label>Sort profiles by</ion-label>
            </ion-list-header>

            <ion-item>
              <ion-label>Online Now</ion-label>
              <ion-radio slot="start" value="online" @click="updateProfileResults('online')"></ion-radio>
            </ion-item>

            <ion-item>
              <ion-label>Most Popular</ion-label>
              <ion-radio slot="start" value="popular" @click="updateProfileResults('popular')"></ion-radio>
            </ion-item>

            <ion-item>
              <ion-label>Recently Joined</ion-label>
              <ion-radio slot="start" value="recent" @click="updateProfileResults('recent')"></ion-radio>
            </ion-item>

            <ion-item>
              <ion-label>Random</ion-label>
              <ion-radio slot="start" value="random" @click="updateProfileResults('random')"></ion-radio>
            </ion-item>

          </ion-radio-group>
        </ion-list>    
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonList, IonRadioGroup, IonListHeader, IonItem, IonLabel, IonRadio } from '@ionic/vue';
import ModalHeader from '../../components/ModalHeader.vue';
import { defineComponent, ref } from 'vue';
import doAuth from '../../services/auth';
import { useStore } from "vuex";
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'ProfileSort',
  props: [],
  components: { IonPage, IonContent, ModalHeader, IonList, IonRadioGroup, IonListHeader, IonItem, IonLabel, IonRadio },
  data() {
    return {
      isAuth: false
    }
  },
  beforeMount: function() {
    this.isAuth = doAuth()
  },
  setup() {
    const sortBy = ref('random');
    const store = useStore();
    const router = useRouter();

    function goBack() {
      router.go(-1)
    }

    return {
      store, sortBy, goBack, router
    }
  },
  methods: {
    updateProfileResults: function(val) {
      this.sortBy = val
      this.router.go(-1)
    }
  }
});
</script>